import React from 'react';

const GoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M3.63605 14.9499C3.24552 15.3404 3.24552 15.9736 3.63605 16.3641C4.02657 16.7546 4.65973 16.7546 5.05026 16.3641L3.63605 14.9499ZM14.9498 3.63615L3.63605 14.9499L5.05026 16.3641L16.364 5.05037L14.9498 3.63615Z"
        fill="#B4B5B7"
      />
      <path
        d="M15.6569 4.34315H8.5858"
        stroke="#B4B5B7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.6569 11.4142V4.34313"
        stroke="#B4B5B7"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default GoIcon;
