import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.4);
`;

export default Container;
