import React from 'react';

const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M3.27638 4.81125C5.54888 7.725 9.74513 13.125 9.74513 13.125V19.875C9.74513 20.4937 10.2514 21 10.8701 21H13.1201C13.7389 21 14.2451 20.4937 14.2451 19.875V13.125C14.2451 13.125 18.4301 7.725 20.7026 4.81125C21.2764 4.06875 20.7476 3 19.8139 3H4.16513C3.23138 3 2.70263 4.06875 3.27638 4.81125Z"
        fill="#B4B5B7"
      />
    </svg>
  );
};

export default FilterIcon;
