import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.div`
  color: #8f9094;
`;

export const List = styled.div`
  display: flex;
  gap: 12px;
`;
