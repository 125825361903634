import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 1.63rem 34.4% 0;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.88rem;
`;
export const List = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;
`;
export const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 12px 10px;
  background-color: #36c05c;
  color: #ffffff;
  margin-top: 40px;
`;
export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a5a6a9;
  padding: 16px 12px;
`;
