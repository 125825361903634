import React from 'react';

const XIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="X">
        <path
          id="Vector 120"
          d="M19 5L5 19"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Vector 121"
          d="M19 19L5 5"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default XIcon;
