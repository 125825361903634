import React from 'react';

const NoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.2C5.44772 3.2 5 3.69249 5 4.3V19.7C5 20.3075 5.44772 20.8 6 20.8H18C18.5523 20.8 19 20.3075 19 19.7V12C19 11.3925 19.4477 10.9 20 10.9C20.5523 10.9 21 11.3925 21 12V19.7C21 21.5225 19.6569 23 18 23H6C4.34315 23 3 21.5225 3 19.7V4.3C3 2.47746 4.34315 1 6 1H12C12.5523 1 13 1.49249 13 2.1C13 2.70751 12.5523 3.2 12 3.2H6Z"
        fill="#8F9094"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06437 10.6638C8.91137 10.8168 8.81213 11.0153 8.78153 11.2295L8.31012 14.5293C8.21584 15.1893 8.78153 15.755 9.44149 15.6607L12.7413 15.1893C12.9555 15.1587 13.154 15.0595 13.307 14.9065L20.8495 7.364C22.0211 6.19242 22.0211 4.29293 20.8495 3.12136C19.6779 1.94978 17.7784 1.94978 16.6068 3.12136L9.06437 10.6638ZM17.3139 8.07111L12.1285 13.2566L10.4786 13.4923L10.7143 11.8423L15.8997 6.65689L17.3139 8.07111ZM18.7282 6.65689L17.3139 5.24268L18.0211 4.53557C18.4116 4.14504 19.0447 4.14504 19.4353 4.53557C19.8258 4.92609 19.8258 5.55926 19.4353 5.94978L18.7282 6.65689Z"
        fill="#8F9094"
      />
    </svg>
  );
};

export default NoteIcon;
