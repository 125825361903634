import React from 'react';

const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M6 14L18 14"
        stroke="#8F9094"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 18L18 18"
        stroke="#8F9094"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7Z"
        stroke="#8F9094"
        strokeWidth="2"
      />
      <path d="M8 2V4" stroke="#8F9094" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M16 2V4"
        stroke="#8F9094"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CalenderIcon;
