import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34 25.5542C34 26.4719 33.8589 27.2933 33.5766 28.0186C33.2943 28.729 32.8932 29.3285 32.3733 29.8169C31.8682 30.3054 31.2591 30.6754 30.546 30.927C29.8329 31.1786 29.0381 31.3044 28.1616 31.3044C27.2851 31.3044 26.4903 31.1712 25.7772 30.9048C25.0642 30.6384 24.4476 30.261 23.9277 29.7725C23.4226 29.2693 23.0289 28.6624 22.7466 27.952C22.4644 27.2415 22.3232 26.4423 22.3232 25.5542C22.3232 24.6809 22.4644 23.8891 22.7466 23.1786C23.0289 22.4682 23.4226 21.8687 23.9277 21.3803C24.4476 20.8771 25.0642 20.4922 25.7772 20.2258C26.4903 19.9594 27.2851 19.8262 28.1616 19.8262C29.0381 19.8262 29.8329 19.9668 30.546 20.248C31.2591 20.5144 31.8682 20.8993 32.3733 21.4025C32.8932 21.8909 33.2943 22.4904 33.5766 23.2008C33.8589 23.9113 34 24.6957 34 25.5542Z"
        fill="#36C05C"
      />
      <path
        d="M12.7066 3.47154C12.7066 4.0277 12.6236 4.52556 12.4575 4.9651C12.2915 5.39568 12.0555 5.75898 11.7497 6.05501C11.4526 6.35103 11.0943 6.57529 10.6748 6.72779C10.2553 6.88028 9.78782 6.95653 9.27223 6.95653C8.75664 6.95653 8.28912 6.8758 7.86966 6.71433C7.4502 6.55286 7.08754 6.32412 6.78168 6.02809C6.48456 5.7231 6.25298 5.35532 6.08695 4.92474C5.92091 4.49416 5.83789 4.00976 5.83789 3.47154C5.83789 2.94228 5.92091 2.46237 6.08695 2.03179C6.25298 1.60121 6.48456 1.23791 6.78168 0.94189C7.08754 0.636897 7.4502 0.403667 7.86966 0.242201C8.28912 0.0807335 8.75664 0 9.27223 0C9.78782 0 10.2553 0.0852186 10.6748 0.255656C11.0943 0.417123 11.4526 0.650353 11.7497 0.955346C12.0555 1.25137 12.2915 1.61467 12.4575 2.04525C12.6236 2.47582 12.7066 2.95125 12.7066 3.47154Z"
        fill="#36C05C"
      />
      <path
        d="M29.8787 16.3447C29.8787 16.6227 29.8372 16.8717 29.7541 17.0914C29.6711 17.3067 29.5532 17.4884 29.4002 17.6364C29.2517 17.7844 29.0725 17.8965 28.8628 17.9728C28.6531 18.049 28.4193 18.0872 28.1615 18.0872C27.9037 18.0872 27.6699 18.0468 27.4602 17.9661C27.2505 17.8853 27.0692 17.7709 26.9162 17.6229C26.7677 17.4704 26.6519 17.2865 26.5689 17.0713C26.4858 16.856 26.4443 16.6138 26.4443 16.3447C26.4443 16.08 26.4858 15.8401 26.5689 15.6248C26.6519 15.4095 26.7677 15.2278 26.9162 15.0798C27.0692 14.9273 27.2505 14.8107 27.4602 14.73C27.6699 14.6493 27.9037 14.6089 28.1615 14.6089C28.4193 14.6089 28.6531 14.6515 28.8628 14.7367C29.0725 14.8174 29.2517 14.9341 29.4002 15.0866C29.5532 15.2346 29.6711 15.4162 29.7541 15.6315C29.8372 15.8468 29.8787 16.0845 29.8787 16.3447Z"
        fill="#36C05C"
      />
      <path
        d="M26.4443 25.2072C26.4443 26.0525 26.5939 26.7038 26.8931 27.1611C27.2054 27.6046 27.6346 27.8263 28.181 27.8263C28.7274 27.8263 29.1437 27.5976 29.4299 27.1403C29.7291 26.683 29.8787 26.0386 29.8787 25.2072C29.8787 24.3757 29.7291 23.7383 29.4299 23.2948C29.1307 22.8375 28.7079 22.6089 28.1615 22.6089C27.6151 22.6089 27.1923 22.8375 26.8931 23.2948C26.5939 23.7383 26.4443 24.3757 26.4443 25.2072Z"
        fill="white"
      />
      <path
        d="M6.37416 31.9653C6.86363 31.9885 7.29747 32.0002 7.67569 32.0002C9.34432 32.0002 10.8683 31.7679 12.2477 31.3034C13.6271 30.8389 14.8063 30.1422 15.7852 29.2132C16.7864 28.261 17.554 27.0997 18.0879 25.7295C18.6219 24.336 18.8889 22.7103 18.8889 20.8523C18.8889 18.9479 18.6108 17.2989 18.0546 15.9054C17.4984 14.5119 16.7085 13.3623 15.6851 12.4566C14.6839 11.5276 13.4714 10.8424 12.0475 10.4012C10.6458 9.95989 9.07734 9.73926 7.34197 9.73926C6.49653 9.73926 5.53985 9.79732 4.47192 9.91344C3.404 10.0063 2.3917 10.2154 1.43502 10.5405C0.990053 10.7031 0.634079 10.9237 0.367098 11.2024C0.122366 11.4579 0 11.8295 0 12.3172V28.5861C0 29.7938 0.61183 30.6415 1.83549 31.1292C2.28046 31.315 2.7588 31.4544 3.27051 31.5473C3.80447 31.6634 4.32731 31.7563 4.83902 31.826C5.37298 31.8956 5.8847 31.9421 6.37416 31.9653Z"
        fill="#36C05C"
      />
      <path
        d="M5.83789 26.0745C5.83789 26.5884 6.20319 27.0293 6.71268 27.0964C7.05827 27.1191 7.38226 27.1305 7.68466 27.1305C8.41905 27.1305 9.08863 27.0168 9.69342 26.7895C10.3198 26.5621 10.849 26.2098 11.281 25.7323C11.7346 25.2549 12.0802 24.6411 12.3178 23.8909C12.577 23.118 12.7066 22.1972 12.7066 21.1288C12.7066 19.0827 12.253 17.5482 11.3458 16.5252C10.4386 15.4794 9.22903 14.9565 7.71706 14.9565C7.41466 14.9565 7.10147 14.9679 6.77747 14.9906C6.23692 15.0313 5.83789 15.4962 5.83789 16.0382V26.0745Z"
        fill="white"
      />
      <path
        d="M18.545 22.0871C16.2964 20.3773 13.7091 22.159 12.8385 22.8674C12.6379 23.0306 12.3965 23.0584 12.2997 22.8187C12.2022 22.5775 12.0947 22.1498 12.0198 21.3915C11.8849 20.0256 12.0678 19.6252 12.1525 19.5162C12.1749 19.4874 12.206 19.4689 12.2369 19.4496C12.7024 19.1582 16.6396 16.794 19.0602 18.6089C20.8238 19.9311 21.8076 21.3915 23.5248 23.1306C24.1781 23.7923 25.0313 24.0109 25.7239 24.0634C26.3598 24.1117 26.9591 24.6022 26.9591 25.24V25.3915C26.9591 25.8671 26.9329 26.2099 26.901 26.4512C26.8582 26.7743 26.5965 26.9869 26.2714 27.0101C25.3598 27.0752 23.4687 27.0738 22.4945 26.0871C20.6056 24.1741 20.2201 23.3609 18.545 22.0871Z"
        fill="#1A8D3A"
      />
    </svg>
  );
};

export default LogoIcon;
