import styled from '@emotion/styled';

export const Container = styled.div`
  border: 1px solid #36c05c;
  border-radius: 8px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Mate = styled.div`
  color: #36c05c;
`;
