import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 16rem;
  border-radius: 0.5rem;
  background: linear-gradient(262deg, #9dffb8 -41.63%, #58d47b 96.93%);
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  position: absolute;
  left: 10%;
  width: 50%;
`;
