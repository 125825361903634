import styled from '@emotion/styled';

export const Container = styled.div`
  border: 1px solid #eff0f2;
  border-radius: 8px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Tag = styled.div`
  color: #a5a6a9;
`;
