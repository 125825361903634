import React from 'react';
import * as S from './style';
import Member from './Member';

const MemberList = () => {
  return (
    <S.Container>
      <Member />
    </S.Container>
  );
};

export default MemberList;
