const theme = {
  color: {
    main: '#36C05C',
    black: '#333333',
    white: '#ffffff',
    gray: {
      900: '#4F4F51',
      800: '#666669',
      700: '#828387',
      600: '#8f9094',
      500: '#A5A6A9',
      400: '#B4B5B7',
      300: '#DBDCDE',
      200: '#EFF0F2',
      100: '#F5F6F8',
    },
    system: '#E53A3A',
  },
  fontStyle: {
    h1: {
      semi: {
        fontSize: '36px',
        fontWeight: '600',
        lineHeight: '120%',
      },
      reg: {
        fontSize: '36px',
        fontWeight: '400',
        lineHeight: '120%',
      },
    },
    h2: {
      semi: { fontSize: '32px', fontWeight: '600', lineHeight: '120%' },
      reg: { fontSize: '32px', fontWeight: '400', lineHeight: '120%' },
    },
    h3: {
      semi: { fontSize: '28px', fontWeight: '600', lineHeight: '130%' },
      reg: { fontSize: '28px', fontWeight: '400', lineHeight: '130%' },
    },
    h4: {
      semi: { fontSize: '24px', fontWeight: '600', lineHeight: '130%' },
      reg: { fontSize: '24px', fontWeight: '400', lineHeight: '130%' },
    },
    m1: {
      semi: { fontSize: '20px', fontWeight: '600', lineHeight: '150%' },
      reg: { fontSize: '20px', fontWeight: '400', lineHeight: '150%' },
    },
    m2: {
      semi: { fontSize: '18px', fontWeight: '600', lineHeight: '150%' },
      reg: { fontSize: '18px', fontWeight: '400', lineHeight: '150%' },
    },
    m3: {
      semi: { fontSize: '16px', fontWeight: '600', lineHeight: '165%' },
      reg: { fontSize: '16px', fontWeight: '400', lineHeight: '165%' },
    },
    label: {
      semi: { fontSize: '14px', fontWeight: '600', lineHeight: '150%' },
      reg: { fontSize: '14px', fontWeight: '400', lineHeight: '150%' },
    },
  },
};

export default theme;
