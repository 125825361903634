import React from 'react';

const ReportIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 21.5L22 21.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 8.7258C20 1.75806 4 1.75807 4 8.7258V21.5H20V8.7258Z"
        fill="#FF0000"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.2273V21.5H16V14.2273C16 10.5909 8 10.5909 8 14.2273Z"
        fill="#FF6B00"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReportIcon;
